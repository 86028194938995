import React, {useEffect, useRef, useState, useMemo} from "react";
import * as d3 from "d3";
import {labelFormatter} from "../../../common/helpers/utils/Formatter";

const BellCurve = ({
  data,
  kpi,
  formData,
  setFormData,
  platform,
  activeSubPlatform,
  activeCategory,
  activeType,
  isEditMode,
  isreverse,
}) => {
  const svgRef = useRef(null);
  const currentdata = useMemo(() => {
    return (
      formData[platform]?.[activeSubPlatform]?.[activeCategory]?.[activeType] ||
      {}
    );
  }, [formData, platform, activeSubPlatform, activeCategory, activeType]);
  const [percentiles, setPercentiles] = useState({
    excellent_score: Number(currentdata.excellent_score) || 0,
    good_score: Number(currentdata.good_score) || 0,
    average_score: Number(currentdata.average_score) || 0,
    low_score: Number(currentdata.low_score) || 0,
  });

  useEffect(() => {
    if (currentdata) {
      setPercentiles({
        excellent_score: Number(currentdata.excellent_score),
        good_score: Number(currentdata.good_score),
        average_score: Number(currentdata.average_score),
        low_score: Number(currentdata.low_score),
      });
    }
  }, [currentdata]); // Trigger whenever currentdata changes

  // Define percentile lines
  const percentileLines = [
    {key: "excellent_score", color: "#00a318", label: "excellent_score"},
    {key: "good_score", color: "#b2ff6b", label: "good_score"},
    {key: "average_score", color: "#FFFF00", label: "average_score"},
    {key: "low_score", color: "#ff6344", label: "low_score"},
  ];

  useEffect(() => {
    const bins = data.histogram;
    const width = 760;
    const height = 550;
    const margin = {top: 145, right: 70, bottom: 40, left: 70};

    // Define scales
    const x = d3
  .scaleLinear()
  .domain([
    Math.min(0, d3.min(bins, (d) => d.p1)),
    Math.max(
      d3.max(bins, (d) => d.p2),
      ...Object.values(percentiles)
    ),
  ])
  .range(
    isreverse
      ? [width - margin.right, margin.left]
      : [margin.left, width - margin.right]
  );


    const y = d3
      .scaleLinear()
      .domain([0, d3.max(bins, (d) => d.count)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    svg.selectAll("*").remove(); // Clear existing elements before rendering

    // Add background regions for the score ranges
    const scoreRanges = isreverse
      ? [
          { min: percentiles.low_score, max: x.domain()[1], color: "#cf0000" },
          { min: percentiles.average_score, max: percentiles.low_score, color: "#ff6344" },
          { min: percentiles.good_score, max: percentiles.average_score, color: "#FFFF00" },
          { min: percentiles.excellent_score, max: percentiles.good_score, color: "#b2ff6b" },
          { min: d3.min([0, x.domain()[0]]), max: percentiles.excellent_score, color: "#00a318" },
        ]
      : [
          { min: d3.min([0, x.domain()[0]]), max: percentiles.low_score, color: "#cf0000" },
          { min: percentiles.low_score, max: percentiles.average_score, color: "#ff6344" },
          { min: percentiles.average_score, max: percentiles.good_score, color: "#FFFF00" },
          { min: percentiles.good_score, max: percentiles.excellent_score, color: "#b2ff6b" },
          { min: percentiles.excellent_score, max: x.domain()[1], color: "#00a318" },
        ];

    // Shade regions based on the score ranges
    scoreRanges.forEach((range) => {
      // Map min and max values to x-axis positions
      let xStart = x(range.min);
      let xEnd = x(range.max);

      // Correct xStart and xEnd if the axis is reversed
      if (isreverse) {
        [xStart, xEnd] = [xEnd, xStart];
      }

      // Ensure positive width and correct starting position
      const xPosition = Math.min(xStart, xEnd);
      const xWidth = Math.abs(xEnd - xStart);

      if (xWidth > 0) { // Only render if the width is positive
        svg
          .append("rect")
          .attr("x", xPosition)
          .attr("width", xWidth)
          .attr("y", margin.top)
          .attr("height", height - margin.top - margin.bottom)
          .attr("fill", range.color)
          .attr("opacity", 0.9);
      }
    });

    // Render histogram bars
    svg
      .append("g")
      .attr("fill", "silver")
      .attr("opacity", 0.5)
      .selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", (d) => Math.min(x(d.p1), x(d.p2)) + 1) // Start at the smaller x position
      .attr("width", (d) => Math.abs(x(d.p2) - x(d.p1)) - 1) // Ensure width is positive
      .attr("y", (d) => y(d.count))
      .attr("height", (d) => y(0) - y(d.count));

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickFormat((d) => d >= 1_000_000 ? d3.format(".2s")(d).replace("G", "B") : d)
          .tickSizeOuter(0)
      )
      .call((g) => g.selectAll(".domain, .tick line").attr("stroke-width", 2));

    svg
      .append("g")
      .attr(
        "transform",
        isreverse
          ? `translate(${width - margin.right},0)`
          : `translate(${margin.left},0)`
      )
      .call(isreverse ? d3.axisRight(y) : d3.axisLeft(y))
      .call((g) => g.selectAll(".domain, .tick line").attr("stroke-width", 2));

    // Add X-axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", (width + margin.left - margin.right) / 2)
      .attr("y", height - margin.bottom / 10)
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .attr("fill", "white")
      .text(labelFormatter(kpi));

    // Add Y-axis label
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `rotate(-90)`)
      .attr("x", -(height + margin.top - margin.bottom) / 2) // Center along Y-axis
      .attr("y", isreverse
          ? width - margin.right / 2 + 30 
          : margin.left / 3 - 10 
      )
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .attr("fill", "white")
      .text("Number of Assets");

    // Generate and render bell curve
    const bellCurve = generateBellCurve(data.kde_data);

    // Calculate the maximum height of the histogram bins
    const maxHistogramHeight = d3.max(bins, (d) => d.count);
    
    // Scale the KDE values to match the histogram's height
    const scaledBellCurve = bellCurve.map((point) => ({
      x: point.x,
      y: point.y * (maxHistogramHeight / d3.max(bellCurve, (d) => d.y)), // Normalize KDE y-values
    }));
    
    // Define the line generator for the bell curve
    const line = d3
      .line()
      .x((d) => x(d.x)) // Use the x-scale for positioning
      .y((d) => y(d.y)); // Use the y-scale for the scaled bell curve

    // Append the scaled bell curve as a path to the SVG
    svg
      .append("path")
      .datum(scaledBellCurve) // Use the scaled bell curve data
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3)
      .attr("d", line); // Generate the path using the line generator

    // Add percentile lines with draggable handles
    percentileLines.forEach((line) => {
      // Draw line
      const lineHeights = ["low_score", "average_score", "good_score", "excellent_score"]
      .reduce((acc, key, index) => {
        acc[key] = height - margin.bottom - (height - margin.top) * (0.95 + index * 0.08);
        return acc;
      }, {});    
    
      svg
        .append("line")
        .attr("x1", x(percentiles[line.key] || 0))
        .attr("x2", x(percentiles[line.key] || 0))
        .attr("y1", lineHeights[line.key])
        .attr("y2", height - margin.bottom)
        .attr("stroke", "white")
        .attr("stroke-width", 3)
        .attr("opacity", 0.8)
        .attr("class", `${line.key}-line draggable-line`)
        .style("cursor", "ew-resize")
        .call(
          d3
            .drag()
            .on("drag", (event) =>
              handleDrag(event, line.key, x, width, margin.left, margin.right)
            )
        );

      // Add handle (rectangle + triangle)
      svg
        .append("g")
        .attr("class", `${line.key}-handle`)
        .style("cursor", "pointer")
        .call(
          d3
            .drag()
            .on("drag", (event) =>
              handleDrag(event, line.key, x, width, margin.left, margin.right)
            )
        )
        .each(function () {
          const cx = x(percentiles[line.key] || 0); // X position based on percentile
          const cy = lineHeights[line.key] - 10; // Adjusted to be below the axis
          const size = 10; // Size of the pentagon
          const height = 2 * size; // Total height of the pentagon
          const width = size * 1.5; // Width of the rectangle part

          // Rectangle (moved to the top part of the inverted pentagon)
          d3.select(this)
            .append("rect")
            .attr("x", cx - width / 2)
            .attr("y", cy) // Position the rectangle part on top
            .attr("width", width)
            .attr("height", height)
            .attr("fill", "white");

          // Triangle (now the bottom part of the inverted pentagon)
          d3.select(this)
            .append("polygon")
            .attr(
              "points",
              [
                [cx, cy + height + size], // bottom point of the triangle (formerly top point)
                [cx - width / 2, cy + height], // top left of the triangle
                [cx + width / 2, cy + height], // top right of the triangle
              ]
                .map((p) => p.join(","))
                .join(" ")
            )
            .attr("fill", "white");
        });

      // Add value text above the pentagon
      svg
        .append("text")
        .attr("x", x(percentiles[line.key] || 0))
        .attr("y", lineHeights[line.key] - 15)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("font-weight", "bold")
        .attr("fill", "white")
        .text(`${(percentiles[line.key] || 0).toFixed(4)}`)
        .attr("class", `${line.key}-value`);
    });

    // Updated handleDrag function
    function handleDrag(
      event,
      lineKey,
      xScale,
      width,
      leftMargin,
      rightMargin
    ) {
      const newX = Math.min(width - rightMargin, Math.max(leftMargin, event.x));
      const newPercentileValue = xScale.invert(newX);
      const updatedPercentiles = {...percentiles};

      // Enforce boundaries to prevent overlap
      if (lineKey === "low_score") {
        if (isreverse) {
          updatedPercentiles.low_score = Math.max(
            percentiles.average_score + 0.0001,
            newPercentileValue
          );
        } else {
          updatedPercentiles.low_score = Math.min(
            newPercentileValue,
            percentiles.average_score - 0.0001
          );
        }
      } else if (lineKey === "average_score") {
        if (isreverse) {
          updatedPercentiles.average_score = Math.max(
            percentiles.good_score + 0.0001,
            Math.min(newPercentileValue, percentiles.low_score - 0.0001)
          );
        } else {
          updatedPercentiles.average_score = Math.max(
            percentiles.low_score + 0.0001,
            Math.min(newPercentileValue, percentiles.good_score - 0.0001)
          );
        }
      } else if (lineKey === "good_score") {
        if (isreverse) {
          updatedPercentiles.good_score = Math.max(
            percentiles.excellent_score + 0.0001,
            Math.min(newPercentileValue, percentiles.average_score - 0.0001)
          );
        } else {
          updatedPercentiles.good_score = Math.max(
            percentiles.average_score + 0.0001,
            Math.min(newPercentileValue, percentiles.excellent_score - 0.0001)
          );
        }
      } else if (lineKey === "excellent_score") {
        if (isreverse) {
          updatedPercentiles.excellent_score = Math.min(
            newPercentileValue,
            percentiles.good_score - 0.0001
          );
        } else {
          updatedPercentiles.excellent_score = Math.max(
            percentiles.good_score + 0.0001,
            newPercentileValue
          );
        }
      }

      setPercentiles(updatedPercentiles);

      // Update formData with the new percentile values
      const updatedFormData = {...formData};
      const targetData =
        updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[
          activeType
        ];

      if (targetData) {
        targetData.excellent_score = parseFloat(updatedPercentiles.excellent_score.toFixed(4));
        targetData.good_score = parseFloat(updatedPercentiles.good_score.toFixed(4));
        targetData.average_score = parseFloat(updatedPercentiles.average_score.toFixed(4));
        targetData.low_score = parseFloat(updatedPercentiles.low_score.toFixed(4));
      }
      // Update the formData state
      setFormData(updatedFormData);

      d3.select(`.${lineKey}-line`)
        .attr("x1", xScale(updatedPercentiles[lineKey]))
        .attr("x2", xScale(updatedPercentiles[lineKey]));

      // Update pentagon handle (rectangle + triangle)
      const handle = d3.select(`.${lineKey}-handle`);
      const cx = xScale(updatedPercentiles[lineKey]);
      const cy = margin.top - 10;
      const size = 12;
      const rectWidth = size * 1.5; // Adjusted for visual clarity
      const rectHeight = size * 2;

      // Update rectangle and triangle simultaneously
      handle
        .select("rect")
        .transition() // Add transition for smooth updates
        .duration(100) // Duration of the transition (ms)
        .attr("x", cx - rectWidth / 2)
        .attr("y", cy)
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .attr(
          "fill",
          "white"
        );

      handle
        .select("polygon")
        .transition() // Add transition for smooth updates
        .duration(100) // Duration of the transition (ms)
        .attr(
          "points",
          [
            [cx, cy + rectHeight], // bottom point of the triangle
            [cx - rectWidth / 2, cy + size], // top left of the triangle
            [cx + rectWidth / 2, cy + size], // top right of the triangle
          ]
            .map((p) => p.join(","))
            .join(" ")
        )
        .attr(
          "fill",
         "white"
        );

      // Update the value text position
      d3.select(`.${lineKey}-value`)
        .transition()
        .duration(100)
        .attr("x", xScale(updatedPercentiles[lineKey]))
        .text(updatedPercentiles[lineKey].toFixed(4));
    }
    // eslint-disable-next-line
  }, [percentiles, data, formData]);

  const generateBellCurve = (data) => {
    const xValues = data.x;
    const yValues = data.y;
    return xValues.map((x, i) => ({x, y: yValues[i]}));
  };

  return (
    <svg
      ref={svgRef}
      style={{
        pointerEvents: isEditMode ? "auto" : "none", // Disable interactions when isEditMode is false
      }}></svg>
  );
};

export default BellCurve;
