import React, {useState, useEffect, useMemo} from "react";
import Spinner from "../../../components/spinner";
import axios from "axios";
import {getAPIConfig} from "../../../common/utils";
import {labelFormatter} from "../../../common/helpers/utils/Formatter";
import {
  capitalize,
  camelToSnakeCase,
  isObjWithKeys
} from "../../../common/helpers/utils/DataHelper";
import ENDPOINTS from "../../../common/endpoints";
import whiteLabel from "../../../assets/whitelabelConfig";
import localisable from "../../../common/constants/localisable";
import BellCurve from "./BellCurve";

const PerformanceForm = ({
  platform,
  metricData,
  formData,
  setFormData,
  typeData,
  activeSubPlatform,
  setActiveSubPlatform,
  activeCategory,
  setActiveCategory,
  activeType,
  setActiveType,
  performanceFieldValues,
  handleChange,
  handleBlur,
  handleSubmit,
  showSuccessMessage,
  message,
  showValidationError,
  errorMessage,
  isEditMode,
  setIsEditMode,
  showSubmitButton,
  isLoading,
  hasError,
  handleCancel,
  lastUpdatedUserDetail,
  showcheckbox,
  initialFormDataRef,
  // setMaxScore,
  isreverse
}) => {
  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isgraphvisible, setIsGraphVisible] = useState(false);
  const [graphData, setGraphData] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  const resetScores = (updatedFormData, targetData) => {
    if (targetData) {
      targetData.excellent_score = "";
      targetData.good_score = "";
      targetData.average_score = "";
      targetData.low_score = "";
    }
    initialFormDataRef.current = structuredClone(updatedFormData);
    setFormData(updatedFormData);
    setIsGraphVisible(false);
    setGraphData({});
  };
  
  const updateScores = (data, updatedFormData, targetData) => {
    if (targetData) {
      targetData.excellent_score = data.pointers?.excellent_score ?? targetData.excellent_score;
      targetData.good_score = data.pointers?.good_score ?? targetData.good_score;
      targetData.average_score = data.pointers?.average_score ?? targetData.average_score;
      targetData.low_score = data.pointers?.low_score ?? targetData.low_score;
    }
    initialFormDataRef.current = structuredClone(updatedFormData);
    setFormData(updatedFormData);
  };
  
  const generateGraph = () => {
    const body = {
      client_id: user.client_id,
      media_platform: platform,
      sub_media_platform: activeSubPlatform,
      category: activeCategory,
      type: activeType,
      metric_field: performanceFieldValues.metric_field,
      is_reverse: isreverse,
    };

    setIsGraphLoading(true);

    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.generateGraph, body, apiHeaderConfig)
      .then((response) => {
        const data = response.data.data;

        if (data && isObjWithKeys(data)) {
          setGraphData(data);
          setIsGraphVisible(data.is_graph_exist);

          const updatedFormData = { ...formData };
          const targetData = updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[activeType];
          updateScores(data, updatedFormData, targetData);
        }
      })
      .catch((error) => {
        console.error("Error generating graph:", error);
  
        const updatedFormData = { ...formData };
        const targetData =
        updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[activeType];
        resetScores(updatedFormData, targetData);
      })
      .finally(() => {
        setIsGraphLoading(false);
      });
  };

  useEffect(() => {
    const updatedFormData = { ...formData };
    const targetData = updatedFormData[platform]?.[activeSubPlatform]?.[activeCategory]?.[activeType];
    if (
      performanceFieldValues.metric_field &&
      performanceFieldValues.metric_field !== "not_applicable" &&
      !performanceFieldValues.use_platform_metric
    ) {
      generateGraph();
    } else if (performanceFieldValues.metric_field === "not_applicable") {
      resetScores(updatedFormData, targetData);
    }
    // eslint-disable-next-line
  }, [activeType, activeCategory, activeSubPlatform, performanceFieldValues.metric_field]);

  return (
    <div className="container pe-4 mb-4">
      <form onSubmit={handleSubmit}>
        {/* Platform */}
        <div className="row align-items-center">
          <div className="col-2 d-flex justify-content-end">
            <h4 className="m-0">Platform:</h4>
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <span className="badge bg-light text-black rounded-pill w-100 text-center pt-2">
                <h5>
                  {platform !== "TradeDesk" ? (
                    <i className={`bi bi-${platform.toLowerCase()} me-2`}></i>
                  ) : (
                    <img
                      src={whiteLabel.TradeDesk_logo}
                      alt="tradedesk_logo"
                      className="me-2 mb-1"
                      style={{height: "20px", width: "20px"}}
                    />
                  )}
                  {platform}
                </h5>
              </span>
            </div>
          </div>
        </div>

        {/* Sub-Platform */}
        <div className="row mt-3">
          <div className="col-2 d-flex justify-content-end">
            <h4 className="m-0">Sub-Platform:</h4>
          </div>
          <div className="col">
            <div className="d-flex gap-2">
              {Object.keys(metricData[platform] || {}).map((subPlatform) => (
                <button
                  key={subPlatform}
                  className={`btn rounded-pill w-100 ${
                    activeSubPlatform === subPlatform
                      ? "btn-primary"
                      : "btn-outline-light"
                  }`}
                  onClick={() => setActiveSubPlatform(subPlatform)}
                  type="button">
                  <i className={`bi bi-${subPlatform.toLowerCase()} me-2`}></i>
                  {capitalize(labelFormatter(subPlatform), true)}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Category & Asset Type */}
        <div className="row mt-3 align-items-center">
          <div className="col-2 d-flex justify-content-end">
            <h4 className="m-0">Category:</h4>
          </div>
          <div className="col">
            <div className="d-flex gap-4 flex-wrap align-items-center">
              <div className="d-flex gap-2 flex-wrap">
                {Object.keys(
                  metricData[platform]?.[activeSubPlatform] || {}
                ).map((category) => (
                  <button
                    key={category}
                    className={`btn rounded-pill px-4 ${
                      activeCategory === category
                        ? "btn-primary"
                        : "btn-outline-light"
                    }`}
                    onClick={() => setActiveCategory(category)}
                    type="button">
                    {capitalize(labelFormatter(category), true)}
                  </button>
                ))}
              </div>
              <div className="d-flex justify-content-end">
                <h4 className="m-0">Asset Type:</h4>
              </div>
              <div className="d-flex gap-2 flex-wrap">
                {Object.keys(
                  metricData[platform]?.[activeSubPlatform]?.[activeCategory] ||
                    {}
                ).map((type) => (
                  <button
                    key={type}
                    className={`btn rounded-pill px-4 ${
                      activeType === type ? "btn-primary" : "btn-outline-light"
                    }`}
                    onClick={() => setActiveType(type)}
                    type="button">
                    {capitalize(labelFormatter(type), true)}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        {showcheckbox && (
          <div className="row mt-3">
            <div className="col-2"></div>
            <div className="col">
              <h5 className="d-flex align-items-center gap-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="use_platform_metric"
                  id="use_platform_metric"
                  checked={performanceFieldValues.use_platform_metric}
                  onChange={(e) =>
                    handleChange(
                      e,
                      platform,
                      activeSubPlatform,
                      activeCategory,
                      activeType
                    )
                  }
                  disabled={!isEditMode && !showSubmitButton}
                />
                <label
                  htmlFor="use_platform_metric"
                  className="form-check-label mb-0">
                  Use default Platform Metric
                </label>
              </h5>
            </div>
          </div>
        )}

        {/* KPI */}
        <div className="row mt-3">
          <div className="col-2 d-flex justify-content-end">
            <h4 className="m-0">KPI:</h4>
          </div>
          <div className="col d-flex align-items-center gap-3">
            <div className="col-auto">
              {/* Metric Field Dropdown/Input */}
              {typeData[activeType]?.metrics.length > 0 ? (
                <select
                  className="form-select"
                  name="metric_field"
                  value={performanceFieldValues.metric_field}
                  onChange={(e) =>
                    handleChange(
                      e,
                      platform,
                      activeSubPlatform,
                      activeCategory,
                      activeType
                    )
                  }
                  required
                  disabled={
                    (!isEditMode && !showSubmitButton) ||
                    performanceFieldValues.use_platform_metric
                  }>
                  {/* <option value="">Select Metric Field</option> */}
                  {typeData[activeType].metrics.map((option, index) => (
                    <option key={index} value={option.metric_name}>
                      {capitalize(
                        labelFormatter(camelToSnakeCase(option.metric_name)),
                        true
                      )}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="form-control"
                  type="text"
                  name="metric_field"
                  value={performanceFieldValues.metric_field}
                  onChange={(e) =>
                    handleChange(
                      e,
                      platform,
                      activeSubPlatform,
                      activeCategory,
                      activeType
                    )
                  }
                  placeholder="No metric fields available"
                  disabled
                />
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                title="Generate Graph"
                onClick={(e) => {
                  e.preventDefault();
                  generateGraph();
                }}
                disabled={
                  !isEditMode ||
                  performanceFieldValues.use_platform_metric ||
                  performanceFieldValues.metric_field === "not_applicable"
                }>
                Go
              </button>
            </div>
            <div className="d-flex ms-auto">
              {/* Action Buttons */}
              {showSubmitButton ? (
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading || hasError}>
                  Submit
                </button>
              ) : (
                <div className="d-flex">
                  {!isEditMode ? (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditMode(true);
                      }}>
                      Edit
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-secondary me-2"
                        disabled={isLoading}
                        onClick={(e) => {
                          handleCancel(e);
                        }}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isLoading || hasError}>
                        Update
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-2"></div>
          <div className="col d-flex justify-content-between align-items-center">
            {/* Success Message */}
            {showSuccessMessage && (
              <div className="d-flex alert alert-success mt-2">{message}</div>
            )}
            {/* Validation Error */}
            {showValidationError && (
              <div className="d-flex alert alert-danger mt-2">{errorMessage}</div>
            )}
            <p className="d-flex ms-auto">
              Last Updated By:{" "}
              {lastUpdatedUserDetail.name || localisable.notSetYet}
            </p>
          </div>
        </div>
        {isGraphLoading ? (
          <Spinner />
        ) : (
          <div className="row">
            {/* Labels and Inputs */}
            <div className="col-4">
              {["Excellent", "Good", "Average", "Low"].map((label, index) => (
                <div className="row mb-3 align-items-center" key={index}>
                  {/* Label Column */}
                  <div className="col-6 d-flex justify-content-end">
                    <h5 className="m-0">{label}:</h5>
                  </div>
                  {/* Input Field Column */}
                  <div className="col-6">
                    <input
                      type="text"
                      id={`${label.toLowerCase()}_score`}
                      className="form-control editing-input"
                      name={`${label.toLowerCase()}_score`}
                      value={
                        formData[platform]?.[activeSubPlatform]?.[
                          activeCategory
                        ]?.[activeType]?.[`${label.toLowerCase()}_score`] ?? ""
                      }
                      onChange={(e) =>
                        handleChange(
                          e,
                          platform,
                          activeSubPlatform,
                          activeCategory,
                          activeType
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(
                          e,
                          platform,
                          activeSubPlatform,
                          activeCategory,
                          activeType,
                          isgraphvisible
                        )
                      }
                      disabled={
                        !isEditMode ||
                        (isEditMode &&
                          (performanceFieldValues.metric_field ===
                            "not_applicable" ||
                            performanceFieldValues.use_platform_metric))
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            {/* Graph */}
            <div className="col-8 d-flex justify-content-center align-items-center border-start border-secondary">
              {isgraphvisible ? (
                <BellCurve
                  data={graphData}
                  kpi={performanceFieldValues.metric_field}
                  formData={formData}
                  setFormData={setFormData}
                  platform={platform}
                  activeSubPlatform={activeSubPlatform}
                  activeCategory={activeCategory}
                  activeType={activeType}
                  isEditMode={isEditMode}
                  isreverse={isreverse}
                  className="ms-auto"
                />
              ) : (
                <h5>
                  Not enough data available to generate Performance thresholds
                </h5>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default PerformanceForm;
