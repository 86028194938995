export const excludedPerformanceKeys = {
    tradedesk: ['Campaign ID', 'Ad Group ID', 'Creative ID'],
    facebook: ['account_id', 'ad_id', 'adset_id', 'campaign_id'],
    instagram: ['account_id', 'ad_id', 'adset_id', 'campaign_id'],
    youtube: ['interaction_event_types'],
    google_ads: ['interaction_event_types'],
    
  };
  
export const excludedPlatformSpecificKeys = {
    facebook:['external_url'],
    instagram:['external_url'],
    linkedin:['content_landing_page', 'serving_hold_reasons'],
    google_ads:['display_url', 'final_urls', 'youtube_url'],
    youtube:['display_url', 'final_urls', 'youtube_url'],
    Non_Digital: ['audio', 'color_range', 'common_name', 'duration', 'encoding', 'file_owner', 'filepath'],
  };

export const topPerformanceKeys = {
  facebook:['clicks', 'ctr', 'spend'],
  instagram:['clicks', 'ctr', 'spend'],
  google_ads: ["active_view_cpm", "active_view_ctr", "active_view_impressions", "active_view_viewability", "average_cpc", "average_cpm", "average_cpv", "average_cpe", "average_time_on_site ", "clicks", "conversions", "cost", "ctr", "engagements", "engagement_rate", "impressions", "interaction_rate", "interactions", "video_view_rate", "video_views"],
  google_analytics: ['performance_start_date', 'performance_end_date' ],
  youtube: ["active_view_cpm", "active_view_ctr", "active_view_impressions", "active_view_viewability", "average_cpc", "average_cpm", "average_cpe", "average_cpv", "average_time_on_site ", "clicks", "conversions", "cost", "ctr", "engagements", "engagement_rate", "impressions", "interaction_rate", "interactions", "video_view_rate", "video_views"],
  reddit: ['ad_id','spend','ctr', ]
};

export const topPlatformKeys = {
  google_ads: ['ad_id','ad_start_date','ad_end_date','ad_status', 'ad_type', 'ad_group_id', 'ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'ad_group_name', 'ad_group_type' ,'campaign_id', 'campaign_start_date','campaign_end_date', 'campaign_status', 'campaign_name'],
  youtube: ['ad_id','ad_start_date','ad_end_date','ad_status', 'ad_type', 'ad_group_id', 'ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'ad_group_name', 'ad_group_type' ,'campaign_id', 'campaign_start_date','campaign_end_date','campaign_status', 'campaign_name'],
  google_analytics: ['ad_start_date','ad_end_date','ad_status','ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'campaign_start_date','campaign_end_date', 'campaign_status'],
  facebook: ['ad_id','ad_start_date','ad_end_date','ad_status','adset_id', 'ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'campaign_id', 'campaign_start_date','campaign_end_date', 'campaign_status'],
  instagram: ['ad_id','ad_start_date','ad_end_date','ad_status','adset_id', 'ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'campaign_id', 'campaign_start_date','campaign_end_date', 'campaign_status'],
  linkedin: ['ad_start_date','ad_end_date','ad_status','ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'campaign_start_date','campaign_end_date', 'campaign_status'],
  reddit: ['ad_start_date','ad_end_date','ad_status','ad_group_start_date', 'ad_group_end_date', 'ad_group_status', 'campaign_created_at', 'campaign_updated_at', 'campaign_start_date','campaign_end_date', 'campaign_status'],
  tradedesk: ['creative_id', 'creative_name', 'creative_availability', 'ad_start_date', 'ad_end_date', 'ad_status', 'ad_group_id', 'ad_group_created_time', 'ad_group_start_date','ad_group_end_date', 'ad_group_status', 'ad_group_name', 'ad_group_availability', 'ad_group_channel_id', 'campaign_id', 'campaign_start_date', 'campaign_end_date', 'campaign_status', 'campaign_name']
};