const CardviewConfig = {
    facebook: ['ctr', 'cpc', 'clicks', 'impressions', 'reach', 'spend'],
    instagram: ['ctr', 'cpc', 'clicks', 'impressions', 'reach', 'spend'],
    google_ads: ['ctr', 'active_view_ctr', 'average_cpc', 'engagements', 'cost', 'clicks'],
    youtube: ['ctr', 'active_view_ctr', 'average_cpc', 'engagements', 'cost', 'clicks'],
    tradedesk: ['Clicks', 'Impressions', 'Advertiser Cost (Adv Currency)','Player Starts'],
    google_analytics: ['activeUsers', 'totalUsers', 'newUsers', 'averageSessionDuration', 'dauPerMau', 'engagedSessions'],
    linkedin: ['ctr', 'engagement', 'clicks', 'likes', 'shares', 'comments'],
    reddit: ['ctr', 'cpc', 'cpv', 'clicks', 'impressions', 'spend']
  };
  
  export default CardviewConfig;