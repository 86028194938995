import axios from "axios";
import ENDPOINTS from "./endpoints";
import CryptoJS from 'crypto-js';
import config from "../config.json";
import { dateFormatter } from "./helpers/utils/DateTime";

const getAPIConfig = () => {
  const authToken = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  };
  return config;
};

const getIcon = (iconText) => {
  return (
    {
      video: "bi-camera-video-fill",
      image: "bi-card-image",
      pdf: "bi-filetype-pdf",
      audio: "bi-volume-up-fill",
      document: "bi-file-earmark-text",
      webpage: "bi-filetype-html",
      link: "bi-link-45deg",
      info: "bi-info-circle-fill",
    }[iconText] || "bi-file-text-fill"
  );
};

const getUser = async () => {
  const config = getAPIConfig();
  try {
    const response = await axios.get(ENDPOINTS.AUTH.getUser, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const encryptPassword = (password) => {
  const secretKey = config.secretKey;
  const encrypted = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();  // This will return a Base64 string
};

const getSessionStorageStartAndEndDates = () => {
  const startDate = sessionStorage.getItem("startDate");
  const endDate = sessionStorage.getItem("endDate");
  return { startDate: dateFormatter(startDate), endDate: dateFormatter(endDate) };

}

export {getAPIConfig, getIcon, getUser, encryptPassword, getSessionStorageStartAndEndDates};
