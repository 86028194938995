
import moment from 'moment';
import { SEARCH_DATE_FORMAT } from '../../constants/constants';


/**
 * @name isValidDate
 * @description Given a string, checks whether proper date or not
 * @param {string} date
 */
const isValidDate = (date) => {
  const dateObj = moment(date);
  return dateObj.isValid();
};

const dateFormatter = (date, convertToString = true, format = SEARCH_DATE_FORMAT, removeTimeZoneAwareness = false) => {
  if(!isValidDate(date)) return null;
  let dateObj = removeTimeZoneAwareness ? moment(date).utc() : moment(date);
  if (convertToString) {
      return dateObj.format(format);
  }
  return dateObj;
};

export { isValidDate, dateFormatter };