import React, {useState, useEffect, useMemo, useRef} from "react";
import Spinner from "../../../components/spinner";
import axios from "axios";
import "../../../Styles/style.css";
import PerformanceForm from "./PerformanceForm";
import {
  capitalize,
  deepCopy,
  getValue,
  isObjWithKeys,
  setValue,
  stringToNumber,
} from "../../../common/helpers/utils/DataHelper";
import {getAPIConfig} from "../../../common/utils";
import ENDPOINTS from "../../../common/endpoints";
import {
  formatPerformanceMetricData,
  getSubMediaPlatform,
  validateScores,
  validateGraphScores
} from "./utils/utils";
import localisable from "../../../common/constants/localisable";
import {labelFormatter} from "../../../common/helpers/utils/Formatter";
import {useParams} from "react-router-dom";

const PerformanceMetric = () => {
  const {platform} = useParams();
  const initialMetricScores = {
    excellent_score: "",
    good_score: "",
    average_score: "",
    low_score: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [metricData, setMetricData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const initialFormDataRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [activeSubPlatform, setActiveSubPlatform] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [activeType, setActiveType] = useState("");
  const [typeData, setTypeData] = useState({});
  const [showValidationError, setShowValidationError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [lastUpdatedUserDetail, setLastUpdatedUserDetail] = useState({});
  const [errors, setErrors] = useState({});
  // const [maxscore, setMaxScore] = useState("");
  const [isreverse, setIsReverse] = useState(false);

  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);
  const isMetricFieldAvailable = isObjWithKeys(metricData);
  const hasError = isObjWithKeys(errors);

  const performanceMetricFieldPath = [
    platform,
    activeSubPlatform,
    activeCategory,
  ].join(".");
  const [performanceFieldValues, setPerformanceFieldValues] = useState(
    getValue(formData, `${performanceMetricFieldPath}.${activeType}`) || {}
  );

  const fetchMetricFields = () => {
    const body = {
      media_platform: platform,
    };
    setIsLoading(true);
    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getFields, body, apiHeaderConfig)
      .then((response) => {
        const data = response.data;
        const subPlatformData = data[platform];
        const subPlatform =
          getValue(Object.keys(data[platform] || {}), "0") || "";
        const categoryData = subPlatformData[subPlatform];
        const activeCategory = getValue(Object.keys(categoryData), "0") || "";
        const typeData = categoryData[activeCategory];
        setTypeData(typeData);
        const activeType = getValue(Object.keys(typeData), "0") || "";
        setActiveSubPlatform(subPlatform);
        setActiveCategory(activeCategory);
        setActiveType(activeType);
        setMetricData(data);
      })
      .catch((error) => {
        console.error("Error fetching metric fields:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchMetricFields();
    // eslint-disable-next-line
  }, [platform]);

  useEffect(() => {
    const subPlatformData = metricData[platform] || {};
    const categoryData = subPlatformData[activeSubPlatform] || {};
    const activeCategory = getValue(Object.keys(categoryData), "0") || "";
    setActiveCategory(activeCategory);
    const typeData = categoryData[activeCategory] || {};
    setTypeData(typeData);
    const activeType = getValue(Object.keys(typeData), "0") || "";
    setActiveType(activeType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubPlatform]);

  useEffect(() => {
    const controller = getMetricConfig();
    setIsEditMode(false);

    let useDefaultPerformanceMetricExists = false;
    const availableCategories =
      getValue(metricData, performanceMetricFieldPath) || {};
    for (const details of Object.values(availableCategories)) {
      useDefaultPerformanceMetricExists =
        useDefaultPerformanceMetricExists ||
        details.has_default_performance_value;
    }
    setErrors({});
    return () => {
      // Cleanup: cancel the request if it's still in progress
      controller.abort();
    };
    // eslint-disable-next-line
  }, [activeSubPlatform, activeCategory, activeType]);

  useEffect(() => {
    const categoryData =
      metricData[platform]?.[activeSubPlatform]?.[activeCategory] || {};
    setTypeData(categoryData);
    const newActiveType = getValue(Object.keys(categoryData), "0") || "";
    setActiveType(newActiveType);
  }, [activeCategory, activeSubPlatform, metricData, platform]);

  useEffect(() => {
    const categoryData =
      metricData[platform]?.[activeSubPlatform]?.[activeCategory] || {};
    setTypeData(categoryData);
  }, [activeType, activeCategory, activeSubPlatform, metricData, platform]);

  const handleChange = (event, platform, subPlatform, category, type) => {
    const { name, value, checked, type: inputType } = event.target;
    if(name === "metric_field"){
      const is_increasing = typeData[activeType].metrics.find(metric => metric.metric_name === value).is_increasing
      setIsReverse(!is_increasing)
    }
  
    // Convert value to number for specific score fields
    const updatedValue =
      inputType === "text" &&
      ["low_score", "average_score", "good_score", "excellent_score"].includes(name)
        ? stringToNumber(value) // Your utility to convert string to number
        : inputType === "checkbox"
        ? checked
        : value;
  
    // Construct the path for nested state update
    const path = [platform, subPlatform, category, ...(type ? [type] : [])].join(".");

    setFormData((prevState) => {
      const modifiedStateValue = { ...prevState };
      const prevStateTypeValue = getValue(prevState, path) || {};
      let valueToSet;
      // Handle special cases
      if (name === "use_platform_metric") {
        valueToSet = {
          ...prevStateTypeValue,
          ...initialMetricScores,
          metric_field: "",
          [name]: checked,
        };
      } else if (name === "metric_field" && value === "not_applicable") {
        valueToSet = {
          ...prevStateTypeValue,
          ...initialMetricScores,
          [name]: value,
        };
      } else {
        // Default case
        valueToSet = {
          ...prevStateTypeValue,
          [name]: updatedValue,
        };
      }
  
      // Update only the field being changed
      setValue(modifiedStateValue, path, valueToSet);
      const updatedValues = getValue(formData, `${performanceMetricFieldPath}.${activeType}`) || {};
      setPerformanceFieldValues(updatedValues);
  
      return modifiedStateValue;
    });
  };
  

  const handleBlur = (event, platform, subPlatform, category, type, isgraphvisible) => {
    const { name, value } = event.target;
  
    // Construct the path for nested state update
    const path = [platform, subPlatform, category, ...(type ? [type] : [])].join(".");
  
    setFormData((prevState) => {
      const modifiedStateValue = { ...prevState };
      const prevStateTypeValue = getValue(prevState, path) || {};
  
      if (isgraphvisible) {
        // Validate only the field that triggered the blur event
        const validatedScores = validateGraphScores(
          { ...prevStateTypeValue, [name]: stringToNumber(value) },
          name, // Pass the name of the changed score
          isreverse
        );
  
        setValue(modifiedStateValue, path, { ...prevStateTypeValue, ...validatedScores });
      } else {
        // Directly update the value without validation
        setValue(modifiedStateValue, path, { ...prevStateTypeValue, [name]: stringToNumber(value) });
      }
  
      // Update performance field values
      const updatedValues =
        getValue(modifiedStateValue, `${performanceMetricFieldPath}.${activeType}`) || {};
      setPerformanceFieldValues(updatedValues);
  
      return modifiedStateValue;
    });
  };    

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditMode((prevState) => !prevState);
    setFormData(deepCopy(initialFormDataRef.current));
  };

  const getMetricConfig = () => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal; // Get the signal from the controller

    setIsLoading(true);
    if (!activeCategory) return controller;
    const body = {
      sub_media_platform: activeSubPlatform || getSubMediaPlatform(platform),
      category: activeCategory,
    };
    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getConfig, body, {
        ...apiHeaderConfig,
        signal,
      })
      .then((response) => {
        const data = response.data;
        const doesConfigExist = getValue(data, "is_config_exist") || false;
        setLastUpdatedUserDetail(data.updated_by || {});
        const configs = getValue(data, "config") || [];
        const formattedCategoryWiseData = {};
        if (configs.length === 0) {
          setIsEditMode(true);
        }

        configs.forEach((eachData = {}) => {
          const {asset_type, use_platform_metric, metric_field, id, is_increasing_score} = eachData;

          // Only process the active type and exclude scores
          if (asset_type === activeType) {
            setIsReverse(!is_increasing_score)
            formattedCategoryWiseData[asset_type] = {
              use_platform_metric,
              metric_field,
              ...(id && {id}),
            };
          }
        });

        if (doesConfigExist) {
          // Ensure any missing types for active type only are added
          if (!formattedCategoryWiseData[activeType]) {
            formattedCategoryWiseData[activeType] = {
              ...initialMetricScores,
              use_platform_metric: false,
              metric_field: "not_applicable",
            };
          }
        }

        const modifiedFormData = {
          [platform]: {
            [activeSubPlatform]: {
              [activeCategory]: formattedCategoryWiseData,
            },
          },
        };

        // Update state
        setFormData(modifiedFormData);
        initialFormDataRef.current = structuredClone(modifiedFormData);
        const recalculatedValues =
          getValue(
            modifiedFormData,
            `${performanceMetricFieldPath}.${activeType}`
          ) || {};
        setPerformanceFieldValues(recalculatedValues);
        setShowSubmitButton(!isObjWithKeys(formattedCategoryWiseData));
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          console.error("Error generating authentication URI:", error);
      });

    return controller; // Return the controller so it can be used to cancel the request
  };

  const addMetricConfig = (activeTypeValues) => {
    setIsLoading(true);

    // Format performance metric data for the active type only
    const body = {
      data: formatPerformanceMetricData(
        {[activeType]: activeTypeValues}, // Pass data for active type only
        activeSubPlatform,
        activeCategory,
        platform
      ),
    };
    axios
      .put(ENDPOINTS.PERFORMANCE_METRIC.addConfig, body, apiHeaderConfig)
      .then((response) => {
        setShowSuccessMessage(true);
        setMessage(response.data.message);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000);
        setIsEditMode(false);
      })
      .catch((error) => {
        setShowValidationError(true);
        setErrorMessage(
          capitalize(labelFormatter(error?.response?.data?.error || ""))
        );
        setTimeout(() => {
          setShowValidationError(false);
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
        getMetricConfig();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Get the performance field values for the active type
    const performanceFieldValues =
      getValue(formData, performanceMetricFieldPath) || {};
    const activeTypeValues = performanceFieldValues[activeType];

    if (!activeTypeValues) {
      setShowValidationError(true);
      setErrorMessage("No data available for the active type");
      setTimeout(() => {
        setShowValidationError(false);
      }, 3000);
      return;
    }

    // Validate scores for the active type only
    const isValid = validateScores(
      activeTypeValues,
      `${performanceMetricFieldPath}.${activeType}`,
      setShowValidationError,
      setErrorMessage,
      isreverse
    );
    if (!isValid) {
      return;
    }
    addMetricConfig(activeTypeValues);
  };

  const renderDataNotAvailableMessage = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="p-2">{localisable.noDataAvailable}</div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div className="position-relative text-white pb-4" style={{top: "68px"}}>
        {isLoading ? (
          <Spinner />
        ) : isMetricFieldAvailable ? (
          <PerformanceForm
            platform={platform}
            metricData={metricData}
            typeData={typeData}
            activeSubPlatform={activeSubPlatform}
            setActiveSubPlatform={setActiveSubPlatform}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            activeType={activeType}
            setActiveType={setActiveType}
            performanceFieldValues={performanceFieldValues}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            showSuccessMessage={showSuccessMessage}
            message={message}
            showValidationError={showValidationError}
            errorMessage={errorMessage}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            showSubmitButton={showSubmitButton}
            isLoading={isLoading}
            hasError={hasError}
            handleCancel={handleCancel}
            lastUpdatedUserDetail={lastUpdatedUserDetail}
            showcheckbox={typeData[activeType].has_default_performance_value}
            formData={formData}
            setFormData={setFormData}
            initialFormDataRef={initialFormDataRef}
            // setMaxScore={setMaxScore}
            handleBlur={handleBlur}
            isreverse={isreverse}
          />
        ) : (
          renderDataNotAvailableMessage()
        )}
      </div>
    </div>
  );
};

export default PerformanceMetric;
