export const categories = ["Organic", "Paid"];
export const status = ["Active", "Available", "Public","Enabled", "Published", "Private", "Unlisted", "Paused", "Removed", "Archived", "Draft"];
export const platforms = [
  "Facebook",
  "Instagram",
  "Google_Ads",
  "Google_Analytics",
  "Youtube",
  "TradeDesk",
  "Linkedin",
  "Reddit",
  "Non_Digital",
];
export const disabledPlatforms = [];
export const types = ["Image", "Video", "Audio", "PDF", "Blog", "Document", "Webpage", "Link", "Text", "Album", "Event"];
export const performance = [5, 4, 3, 2, 1, 0];
// export const ETFs = ["NVDA"];
// export const Tiers = ["Tier1", "Tier2", "Tier3", "Focus Fund"];
export const sizes = [12, 6, 4, 3, 2];

export const ASSET_STATUS_MAPPING = {
  ACTIVE: "Active",
  AVAILABLE: "Available",
  ENABLED: "Enabled",
  PAUSED: "Paused",
  REMOVED: "Removed",
  ARCHIVED: "Archived",
  DRAFT: "Draft",
  PUBLISHED: "Published",
  PUBLIC: "Public",
  PRIVATE: "Private",
  UNLISTED: "Unlisted"
};

export const ASSET_TYPE_MAPPING = {
  image: "Image",
  video: "Video",
  audio: "Audio",
  blog: "Blog",
  pdf: "PDF",
  document: "Document",
  webpage: "Webpage",
  link: "Link",
  text: "Text",
  album: "Album",
  event: "Event"
};

export const PLATFORM_MAPPING = {
  facebook: "Facebook",
  instagram: "Instagram",
  linkedin: "Linkedin",
  reddit: "Reddit",
  google_ads: "Google_Ads",
  google_analytics: "Google_Analytics",
  non_digital: "Non_Digital",
  tradedesk: "TradeDesk",
  youtube: "Youtube",
};